import i18next from './../../i18n'
export const jobData = [
  {
    id: 1,
    name: 'Senior Java Developer',
    periode: i18next.t('job_dkb_periode'),
    info: i18next.t('job_dkb_info'),
    picture: './media/DKB.png'
  },
  {
    id: 2,
    name: 'Java Developer',
    periode: i18next.t('job_iqtig_periode'),
    info: i18next.t('job_iqtig_info'),
    picture: './media/IQTIG.png'
  },
  {
    id: 3,
    name: 'QA-Engineer ',
    periode: i18next.t('job_epost_periode'),
    info: i18next.t('job_epost_info'),
    picture: './media/EPOST.png'
  },
  {
    id: 4,
    name: 'Werkstudent php',
    periode: i18next.t('job_kiebackpeter_periode'),
    info: i18next.t('job_kiebackpeter_info'),
    picture: './media/KP.png'
  },
  {
    id: 5,
    name: 'Werkstudent php',
    periode: i18next.t('job_avenso_periode'),
    info: i18next.t('job_avenso_info'),
    picture: './media/AV.png'
  },
  {
    id: 6,
    name: 'Werkstudent Java',
    periode: i18next.t('job_wincor_periode'),
    info: i18next.t('job_wincor_info'),
    picture: './media/WN.png'
  }
]