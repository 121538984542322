import React from 'react';
import {NavLink} from 'react-router-dom';
import SocialNetwork from "./SocialNetwork";
import { withNamespaces } from "react-i18next";

const Navigation = ({ t }) => {
    return (
        <div className="sidebar">
            <div className="id">
                <div className="idContent">
                    <img src="./media/foto.jpg" alt="profil-pic"/>
                    <h3>Emo Leumassi</h3>
                </div>
            </div>

            <div className="navigation">
                <ul>
                    <li>
                        <NavLink exact to="/" activeClassName="navActive">
                            <i className="fas fa-home"></i>
                            <span>{t('navigation_homepage')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/skills" activeClassName="navActive">
                            <i className="fas fa-cogs"></i>
                            <span>{t('navigation_skills')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/freelances" activeClassName="navActive">
                            <i className="fas fa-images"></i>
                            <span>{t('navigation_freelances')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/certificates" activeClassName="navActive">
                            <i className="fas fa-certificate"></i>
                            <span>{t('navigation_certificates')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/experiences" activeClassName="navActive">
                            <i className="fas fa-images"></i>
                            <span>{t('navigation_experiences')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/projects" activeClassName="navActive">
                            <i className="fas fa-project-diagram"></i>
                            <span>{t('navigation_projects')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/contact" activeClassName="navActive">
                            <i className="fas fa-address-book"></i>
                            <span>{t('navigation_contact')}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>

            <SocialNetwork />
        </div>
    );
};

export default withNamespaces()(Navigation);
