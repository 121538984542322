import React from 'react';
import Navigation from "../components/Nagivation";
import JobExperienceList from "../components/experience/JobExperienceList";

const Experience = () => {
    return (
        <div className="portfolio">
            <Navigation/>
            <JobExperienceList/>
        </div>
    );
};

export default Experience;

