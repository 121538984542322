import React from 'react';
import Navigation from "../components/Nagivation";
import ProjectList from "../components/project/ProjectList";

const Projects = () => {
    return (
        <div className="project">
            <Navigation/>
            <ProjectList/>
        </div>
    );
};

export default Projects;
