import React, {Component} from 'react';
import ProgressBar from "./ProgressBar";

class Programming extends Component {
    state = {
        backend: [
            {id: 1, value: "Java", xp: 2.0},
            {id: 2, value: "Maven/Hibernate/Rest/Gradle", xp: 2.0},
            {id: 4, value: "Spring boot, JPA, Data, ...", xp: 1.75},
            {id: 3, value: "MySQL/PosgreSQL", xp: 1.60},
            {id: 5, value: "Microservices", xp: 1.55},
            {id: 6, value: "Git/GitLab/Bamboo", xp: 1.55},
            {id: 8, value: "Docker", xp: 1.45},
            {id: 7, value: "CI/CD", xp: 1.35},
            {id: 10, value: "Kafka/RabbitMQ", xp: 0.9},
            {id: 9, value: "ApiGateway/Caching", xp: 0.85},
            {id: 11, value: "AWS/Kubernetes", xp: 0.65},
            {id: 12, value: "React/JavaScript/css", xp: 0.60},
        ],
        other: [
            {id: 1, value: "Scrum/Kanban", xp: 2.0},
            {id: 2, value: "Jira/Confluence", xp: 2.0},
        ]
    }

    render() {
        let {backend, other} = this.state;
        const levels = ['2 Jahren', '5 Jahren', '8 Jahren']

        return (
            <div className="programmingFrameworks">
                <ProgressBar
                    programming={backend}
                    className="backendDisplay"
                    levels={levels}
                    title="Backend"
                />
                <ProgressBar
                    programming={other}
                    className="otherDisplay"
                    levels={levels}
                    title="Andere Skills"
                />
            </div>
        );
    }
}

export default Programming;
