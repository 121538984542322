import i18next from './../../i18n'

export const projectData = [
  {
    id: 1,
    name: 'MySQL Cluster ',
    source: 'https://bitbucket.org/kazikamerun/kazi-db/src/master/',
    languagesIcons: ['fab fa-java','fas fa-database','fab fa-docker','fas fa-terminal'],
    info: i18next.t('private_projects_mysql')
  },
  {
    id: 2,
    name: 'OAuth 2 Cluster',
    source: 'https://bitbucket.org/kazikamerun/kazi-oauth/src/develop/',
    languagesIcons: ['fas fa-key','fab fa-java','fas fa-database','fab fa-docker','fas fa-terminal'],
    info: i18next.t('private_projects_oauth')
  },
  {
    id: 3,
    name: 'Registration Service',
    source: 'https://bitbucket.org/kazikamerun/kazi-userservice/src/master/',
    languagesIcons: ['fab fa-java','fas fa-database','fab fa-docker','fas fa-terminal'],
    info: i18next.t('private_projects_registration')
  },
  {
    id: 4,
    name: 'MailService',
    source: 'https://bitbucket.org/kazikamerun/kazi-mailservice/src/master/',
    languagesIcons: ['far fa-envelope','fab fa-java','fab fa-docker','fas fa-database'],
    info: i18next.t('private_projects_mail')
  },
  {
    id: 5,
    name: 'Multi Modules maven',
    source: 'https://bitbucket.org/kazikamerun/kazi/src/master/',
    languagesIcons: ['fab fa-java','fas fa-database','fab fa-docker','fas fa-terminal'],
    info: i18next.t('private_projects_multimodules')
  },
  {
    id: 6,
    name: 'CV in ReactJs',
    source: './media/XXX.pdf',
    languagesIcons: ['fab fa-react','fab fa-js','fab fa-html5','fab fa-css3','fab fa-npm','fab fa-aws'],
    info: i18next.t('private_projects_react')
  }
]