import React from 'react';

const SocialNetwork = () => {
    return (
        <div className="socialNetwork">
            <ul>
                <li>
                    <a href="https://bitbucket.org/kazikamerun/" target="_blank"
                       rel="noopener noreferrer">
                        <i className="fab fa-bitbucket"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/emoleumassi/?originalSubdomain=de" target="_blank"
                       rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="https://stackoverflow.com/users/2538180/emoleumassim" target="_blank"
                       rel="noopener noreferrer">
                        <i className="fab fa-stack-overflow"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.xing.com/profile/Emo_Leumassi/cv" target="_blank"
                       rel="noopener noreferrer">
                        <i className="fab fa-xing"></i>
                    </a>
                </li>
            </ul>
            <div className="signature">
                <p>Emo Leumassi - 2023</p>
            </div>
        </div>
    );
};

export default SocialNetwork;
