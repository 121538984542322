import React from 'react';
import Navigation from "../components/Nagivation";
import Programming from "../components/knowledges/Programming";
import Languages from "../components/knowledges/Languages";

const Skills = () => {
    return (
        <div className="skills">
            <Navigation/>
            <div className="skillsContent">
                <Programming/>
                <Languages/>
            </div>
        </div>
    );
};

export default Skills;
