import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Skills from './pages/Skills';
import Freelance from './pages/Freelance';
import Certificates from './pages/Certificates';
import Contact from './pages/Contact';
import Experience from './pages/Experience';
import NotFound from './pages/NotFound';
import Projects from "./pages/Projects";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/skills" component={Skills}/>
                    <Route path="/freelances" component={Freelance}/>
                    <Route path="/certificates" component={Certificates}/>
                    <Route path="/experiences" component={Experience}/>
                    <Route path="/projects" component={Projects}/>
                    <Route path="/contact" component={Contact}/>
                    <Route component={NotFound}/>
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default App;
