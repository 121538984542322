import i18next from './../../i18n'
export const freelanceData = [
  {
    id: 1,
    name: 'Senior Java Developer',
    periode: i18next.t('project_sick_periode'),
    info: i18next.t('project_sick_info'),
    picture: './media/sick.png'
  },
  {
    id: 2,
    name: 'Senior Java Developer',
    periode: i18next.t('project_tsystems_periode'),
    info: i18next.t('project_tsystems_info'),
    picture: './media/tsystems.png'
  }
]