import React, {Component} from 'react';
import ProgressBar from "./ProgressBar";
import { withNamespaces } from "react-i18next";

class Languages extends Component {
    state = {
        languages: [
            {id: 1, value: "French", xp: 1.86},
            {id: 2, value: "Deutsch", xp: 1.5},
            {id: 3, value: "Englisch", xp: 0.75}
        ]
    }

    render() {
        let {languages} = this.state;

        const { t } = this.props;
        const levels = ['Gut', 'Verhandlungssicher', 'Muttersprache']

        return (
            <div className="languages">
                <ProgressBar
                    programming={languages}
                    className="languagesDisplay"
                    levels={levels}
                    title="Sprachen"
                />
            </div>
        );
    }
}

export default withNamespaces()(Languages);
