import React, {Component} from 'react';

export default class Project extends Component {

    render() {
        let {name, languagesIcons, source, info} = this.props.item

        return (
            <div className="projectItem">
                <div className="icons">
                    {languagesIcons.map(icon =>
                        <i className={icon} key={icon}></i>
                    )}
                </div>
                <h3>{name}</h3>
                <p className="text">{info}</p>
                <div className="sourceCode">
                    <a href={source} rel="noopener noreferrer"
                       className="button" target="_blank">Source Code</a>
                </div>
            </div>
        );
    }
}
