import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import translationEN from './public/locales/en/translation.json';
import translationDE from './public/locales/de/translation.json';
const fallbackLng = 'en';
//const availableLanguages = ['de', 'en', 'fr'];

// the translations
export const languageResources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(detector)
    .use(backend)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        lng: 'en',
        resources: languageResources,
        fallbackLng: fallbackLng, // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;