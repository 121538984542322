import React from 'react';
import Navigation from "../components/Nagivation";
import { withNamespaces } from "react-i18next";
import { Helmet } from "react-helmet"

const Certificates = ({ t }) => {
    return (
        <div className="portfolio">
            <Navigation/>
            <div className="portfolioContent">
                <h3 className="radioDisplay">{t('navigation_certificates')}</h3>
                <div className="contentOben">
                    <div className="contentLeft" data-iframe-width="400" data-iframe-height="600" data-share-badge-id="ce3176e4-2cf6-4681-9986-217854460176" data-share-badge-host="https://www.credly.com"></div>
                    <div className="contentRight" data-iframe-width="400" data-iframe-height="600" data-share-badge-id="d74c6156-79f8-447d-9c6a-2897003e3f9c" data-share-badge-host="https://www.credly.com"></div>
                </div>
                <div className="contentUnten">
                    <div className="contentLeft" data-iframe-width="400" data-iframe-height="600" data-share-badge-id="f0150960-f4de-4fcb-9208-0f5d954e6e34" data-share-badge-host="https://www.credly.com"></div>
                    <div className="contentRight" data-iframe-width="400" data-iframe-height="600" data-share-badge-id="0343b63e-46f5-4f71-82d3-f4152b8f86c4" data-share-badge-host="https://www.credly.com"></div>
                </div>
                <Helmet>
                    <script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script>
                </Helmet>
            </div>
        </div>
    );
};

export default withNamespaces()(Certificates);
