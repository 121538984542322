import React from 'react';
import Navigation from "../components/Nagivation";
import { withNamespaces } from "react-i18next";

const Home = ({ t }) => {
    return (
        <div className="home">
            <Navigation/>
            <div className="homeContent">
                <div className="content">
                    <h1>Emo Leumassi</h1>
                    <h2>{t('home_job_description')}</h2>
                    <div className="pdf">
                        <a href="./media/CV.pdf" download="Emo_CV.pdf">{t('home_cv')}</a>
                        <a href="./media/Zeugnisse.pdf" download="Emo_Certificates.pdf">{t('home_certificates')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Home);
