import React from 'react';

const ProgressBar = (props) => {
    return (
        <div className={props.className}>
            <h3>{props.title}</h3>
            <div className="years">
                <span>Erfahrungen</span>
                { props.levels.map((item) => {return <span>{item}</span>; }) }
            </div>

            <div>
                {
                    props.programming.map((item) =>{
                        let xpYears = 2;
                        let progressBar = item.xp / xpYears * 100+ '%';

                        return (
                            <div key={item.id} className="progressBarList">
                                <li>{item.value}</li>
                                <div className="progressBar" style={{width:progressBar}}></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default ProgressBar;
