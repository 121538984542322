import React from 'react';
import Navigation from "../components/Nagivation";
import FreelanceList from "../components/freelance/FreelanceList";

const Freelance = () => {
    return (
        <div className="portfolio">
            <Navigation/>
            <FreelanceList/>
        </div>
    );
};

export default Freelance;

