import React from 'react';
import Navigation from "../components/Nagivation";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { withNamespaces } from "react-i18next";

const Contact = ({ t }) => {
    return (
        <div className="contact">
            <Navigation/>
            <div className="contactContent">
                <div className="header"></div>
                <div className="contactBox">
                    <h1>{t('contact_page_title')}</h1>
                    <ul>
                        <li>
                            <i className="fas fa-map-marker-alt"></i>
                            <span>Bernau bei Berlin</span>
                        </li>
                        <li>
                            <i className="fas fa-mobile-alt"></i>
                            <CopyToClipboard text="0049 176 77065814">
                                <span className="clickInput"
                                      onClick={() => {alert('kopiert');}}>
                                    0049 176 77065814
                                </span>
                            </CopyToClipboard>
                        </li>
                        <li>
                            <i className="far fa-envelope"></i>
                            <CopyToClipboard text="contact@emoleumassi.com">
                                <span className="clickInput"
                                      onClick={() => {alert('kopiert');}}>
                                    contact@emoleumassi.com
                                </span>
                            </CopyToClipboard>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Contact);
