import React, {Component} from 'react';
import { jobData } from '../data/jobData';
import JobExperience from "./JobExperience";
import { withNamespaces } from "react-i18next";
class JobExperienceList extends Component {
    state = {
        projects:jobData
    };

    render() {
        let {projects} = this.state;

        const { t } = this.props;
        return (
            <div className="portfolioContent">
                <h3 className="radioDisplay">{t('skills_experiences_text')}</h3>

                <div className="projects">
                    {
                        projects.map(item => {
                            return (
                                <JobExperience
                                    key={item.id}
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default withNamespaces()(JobExperienceList);
