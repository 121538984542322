import React, {Component} from 'react';
import { freelanceData } from '../data/freelanceData';
import Freelance from "./Freelance";
import { withNamespaces } from "react-i18next";

class FreelanceList extends Component {
    state = {
        projects:freelanceData
    };

    render() {
        let {projects} = this.state;

        const { t } = this.props;

        return (
            <div className="portfolioContent">
                <h3 className="radioDisplay">{t('navigation_freelances')}</h3>

                <div className="projects">
                    {
                        projects.map(item => {
                            return (
                                <Freelance
                                    key={item.id}
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
export default withNamespaces()(FreelanceList);
