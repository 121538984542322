import React from 'react';
import {NavLink} from 'react-router-dom';
import { withNamespaces } from "react-i18next";

const NotFound = ({ t }) => {
    return (
        <div>
            <div className="notFound">
                <div className="notFoundContent">
                    <h3>{t('not_found_doesnt_exist')}</h3>
                    <NavLink exact to="/">
                        <i className="fas fa-home"></i>
                        <span>{t('not_found_home')}</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(NotFound);
