import React, {Component} from 'react';
import { projectData } from '../data/projectData';
import Project from "./Project";
import { withNamespaces } from "react-i18next";

class ProjectList extends Component {
    state = {
        projects:projectData
    };

    render() {
        let {projects} = this.state;
        const { t } = this.props;

        return (
            <div className="projectContent">
                <h3 className="radioDisplay">{t('private_projects')}</h3>

                <div className="projects">
                    {
                        projects.map(item => {
                            return (
                                <Project
                                    key={item.id}
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
export default withNamespaces()(ProjectList);
